import { CrushingMaterialDB_controller } from './../../../../Services/DB_Controller/CrushingMaterialDB_Controller';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-manage-crushing-type',
  templateUrl: './manage-crushing-type.component.html',
  styleUrls: ['./manage-crushing-type.component.css']
})
export class ManageCrushingTypeComponent implements OnInit {

  rawTypes: any = [];
  CrushingMaterialDB_controllers: CrushingMaterialDB_controller = new CrushingMaterialDB_controller(this.db, this.firestore);
  Code: any;
  ngOnInit() {
  }
  constructor(
    public dialogRef: MatDialogRef<ManageCrushingTypeComponent>,
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.setup();

    }

  cancel(): void {
    this.dialogRef.close();
  }

  setup(){
    this.CrushingMaterialDB_controllers.getCrushCodeList().then(data=>{
      this.rawTypes = data;
      this.rawTypes = this.rawTypes.sort((a, b) => {
        return this.compare(a.value, b.value, true);
      })
    })
  }
  add(){
    if(!this.Code){
      this.toast.warning('Invalid Input!','Warning');
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Add this crush code? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.CrushingMaterialDB_controllers.addCode(this.Code).then(()=>{
          this.setup();
          this.Code = "";
          this.toast.success('New Crush Code added','Added Successfully');

        })
      }
    })

  }
  delete(code){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this crush code ? ' + code.Code;

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.CrushingMaterialDB_controllers.deleteCode(code.id).then(()=>{
          this.setup();
          this.Code = "";
          this.toast.success(code.Code + ' has been deleted','Deleted Successfully');
        })
      }
    })

  }

  compare(a: number | string , b: number | string , isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

}
