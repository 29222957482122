import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-box-number',
  templateUrl: './box-number.component.html',
  styleUrls: ['./box-number.component.css']
})
export class BoxNumberComponent implements OnInit {

    part: any;
    box: any;
    title: any;
    constructor(
      private dialogRef: MatDialogRef<BoxNumberComponent>,
      @Inject(MAT_DIALOG_DATA) data,
      private toast: ToastrService,
      private db: AngularFireDatabase,
      private dialog: MatDialog,
      private firestore: AngularFirestore,
    ) {
      this.title = data.PO_Part_No;
      this.part = data;

    }

    ngOnInit() {
    }
    cancel() {
      this.dialogRef.close(false);
    }

    confirm() {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.height = 'auto';
      dialogConfig.width = '50%';
      const position = {
        top: '5%'
      };
      dialogConfig.position = position;
      dialogConfig.disableClose = true;
      dialogConfig.data = 'Confirm this details?';

      this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
        if (result) {
          const value = {
            ID: this.part.PO_Part_No,
            box: this.box
          }
          this.dialogRef.close(value);
        }
      });
    }

  }
