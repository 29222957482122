import { Customer } from './../../../Services/Object_Classes/Customer/Customer';
import { GenerateInfoComponent } from './generate-info/generate-info.component';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MatSelectionList, MatSelectionListChange, MAT_DIALOG_DATA, ThemePalette } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { AngularFireDatabase } from '@angular/fire/database';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { PartTracker, PurchaseOrder } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';
import { InvoiceDB_controller } from '../../../Services/DB_Controller/InvoiceDB_controller';
import { Invoice } from 'src/app/Services/Object_Classes/Invoice/Invoice';
import { AngularFireAuth } from '@angular/fire/auth';
import { NgxSpinnerService } from 'ngx-spinner';
import { PartDB_controller } from '../../../Services/DB_Controller/PartDB_controller';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { PackingList } from 'src/app/Services/Object_Classes/PackingList/PackingList';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-add-invoice',
  templateUrl: './add-invoice.component.html',
  styleUrls: ['./add-invoice.component.css']
})
export class AddInvoiceComponent implements OnInit {
  poNo = '';
  totalPart: any;
  partNumber: any;
  quantity: any;
  po_controller: PODB_controller = new PODB_controller(this.db);
  invoice_controller: InvoiceDB_controller = new InvoiceDB_controller(this.db);
  availableMachineList: any[] = [];
  selectedMachineList: any[] = [];
  matSelectPO: PurchaseOrder[] = [];
  matSelectPart=[];
  addForm: FormGroup;
  clone = [];
  newPO: PurchaseOrder = new PurchaseOrder();
  shippingDate = new Date();
  shipDate = [];
  searchPart: any;
  searchPO: any;
  check = false;
  check2 = false;
  selectedParts: PartTracker[] = [];
  selectedPack: any = [];

  newInvoice: Invoice = new Invoice();
  email:string;
  @ViewChild('picker', {static: true}) picker: any;
  temporaryInvoiceNum: string = "";
  part_controller: PartDB_controller = new PartDB_controller(this.db, this.storage,this.firestore);
  partsList: Part[] = [];
  // partUnitPrice: any[] = [];
  // partMouldPrice: any[] = [];
  // partRemark: any[] = [];
  priceList: string = "0";
  linkPackingList: PackingList = new PackingList();
  selectedCurrency: string = 'MYR';

  public date: moment.Moment;

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required])
  });

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];
  @ViewChild('packing',{static:false}) packing: MatSelectionList;
  packingList: PackingList[] =[];
  clonePacking = [];
  constructor(
    private dialogRef: MatDialogRef<AddInvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private spinner : NgxSpinnerService,
    private firestore: AngularFirestore,
    private storage : AngularFireStorage
  ) {
    this.addForm = this.fb.group({
      name: '',
    });
    this.angularFireAuth.authState.subscribe(auth=>{
      this.email = auth.email;
    })
    this.temporaryInvoiceNum = data.tempInvoiceNo;
    this.packingList = data.packingList;
    this.clonePacking = this.packingList.slice();

  }

  DynamicSearch(searchValue: string){
    this.packingList = this.clonePacking.filter(u =>
      String(u.PackingList_No).toLowerCase().includes(searchValue.toLowerCase()));

  }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    var customer;
    for (let index = 0; index < this.selectedPack.length; index++) {
      if(customer){
        if(customer !== this.selectedPack[index].Customer){
          this.packing.deselectAll()
          this.toast.warning("Deselect all packing list due to different customer are selected","Warning");
          this.selectedPack = [];
          return;
        }
      }
      customer = this.selectedPack[index].Customer;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };

    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      invoiceNo: this.temporaryInvoiceNum,
      packingList: this.selectedPack
    };
    this.dialog.open(GenerateInfoComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result){
        this.dialogRef.close();
      }
    });
  }


}
