import { Component, Inject, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-quantity',
  templateUrl: './quantity.component.html',
  styleUrls: ['./quantity.component.css']
})
export class QuantityComponent implements OnInit {

  quantity: any;
  validationQuantity: number;
  constructor(
    private dialogRef: MatDialogRef<QuantityComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
  ) {
    this.validationQuantity = data.Quantity;
  }

  ngOnInit(){

  }
  cancel() {
    this.dialogRef.close(false);
  }

  change(event,model:NgModel){
    if(this.quantity > this.validationQuantity){
        model.control.setErrors({invalid: true});
    }
  }

  confirm() {
    this.dialogRef.close(parseFloat(this.quantity));
  }
}
