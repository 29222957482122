import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { RawDB_controller } from 'src/app/Services/DB_Controller/RawDB_controller';
import { RawMaterialInfo } from 'src/app/Services/Object_Classes/RawMaterial/RawMaterial';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
const cloneDeep = require('lodash.clonedeep')
@Component({
  selector: 'app-details-raw',
  templateUrl: './details-raw.component.html',
  styleUrls: ['./details-raw.component.css']
})
export class DetailsRawComponent implements OnInit {

  raw = new RawMaterialInfo();
  RawDB_controllers: RawDB_controller = new RawDB_controller(this.db, this.firestore);
  rawTypes = [];

  constructor(
    public dialogRef: MatDialogRef<DetailsRawComponent>,
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private toast: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.raw =cloneDeep(data);
      this.setup();
    }
  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }
  update(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm update this ' + this.raw.Material_Name;
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close(this.raw);
      }
    });
  }
  setup(){
    this.RawDB_controllers.getRawTypeList().then(data=>{
      this.rawTypes = data;
      this.rawTypes = this.rawTypes.sort((a, b) => {
        return this.compare(a.value, b.value, true);
      })
    })
  }

  compare(a: number | string , b: number | string , isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  change(Rawtype){

    if(Rawtype!="Carton")
      this.raw.Carton_Weight = "";
  }


}
