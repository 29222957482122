import { Machine, ScheduleTracker } from 'src/app/Services/Object_Classes/Machine/Machine';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'qcfilter',
    pure: false
})
export class QCFilterPipe implements PipeTransform {
    transform(items: ScheduleTracker[]): any {

        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        return items.filter(item => item.QCDone);
    }
}
