import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-qty-information',
  templateUrl: './qty-information.component.html',
  styleUrls: ['./qty-information.component.css']
})
export class QtyInformationComponent implements OnInit {

  title:string;
  data:any;

  constructor(
      private dialogRef: MatDialogRef<QtyInformationComponent>,
      @Inject(MAT_DIALOG_DATA) data,
    ) {
      this.title = data.title;
      this.data = data.data;
    }

  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close(false);
  }



}
