import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
import { PartDB_controller } from 'src/app/Services/DB_Controller/PartDB_controller';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { Machine } from 'src/app/Services/Object_Classes/Machine/Machine';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { PurchaseOrder, PartTracker } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';

@Component({
  selector: 'app-select-machine-slot',
  templateUrl: './select-machine-slot.component.html',
  styleUrls: ['./select-machine-slot.component.css']
})
export class SelectMachineSlotComponent implements OnInit {

  dailyDate: Date;
  flag = true;
  minDate: Date;
  maxDate: Date;
  selectSlot: any;
  timeslot = ['0000', '0100', '0200', '0300', '0400', '0500', '0600', '0700', '0800', '0900', '1000', '1100', '1200',
             '1300', '1400', '1500', '1600', '1700', '1800', '1900', '2000', '2100', '2200', '2300'];
  machine: any;
  schedulelist = [];

  constructor(
    private dialogRef: MatDialogRef<SelectMachineSlotComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
    private db: AngularFireDatabase
  ) {
    this.machine = data;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    this.minDate = new Date();
    this.maxDate = new Date(currentYear, currentMonth + 2, 1);
    this.dailyDate = new Date();
    this.getMachine();
  }


  ngOnInit() {}

  async getMachine(){
    // tslint:disable-next-line: one-variable-per-declaration
    const d = new Date(this.dailyDate),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
    const dateString = year + '-' + month + '-' + day;
    const snapshot = await this.db.database.ref('Machine').child(this.machine).child('Schedule').once('value');
    if (snapshot.exists()){
      snapshot.forEach(element => {
        this.schedulelist.push(new Date(element.child('endAt').val()));
      })
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    const info = {
      date: this.dailyDate,
      slot: this.selectSlot.substring(0, 2)
    }

    this.dialogRef.close(info);
  }

  datePicked() {
   // tslint:disable-next-line: one-variable-per-declaration
   const d = new Date(this.dailyDate),
   month = '' + (d.getMonth() + 1),
   day = '' + d.getDate(),
   year = d.getFullYear();
   const dateString = year + '-' + month + '-' + day;


  }



}
