import { ScheduleTracker } from './../../../../Services/Object_Classes/Machine/Machine';
import { Schedule } from './../../../../Services/Object_Classes/Schedule/Schedule';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DowntimeDetailsComponent } from 'src/app/Components/purchaseOrder/downtime-details/downtime-details.component';

@Component({
  selector: 'app-oee-calculation',
  templateUrl: './oee-calculation.component.html',
  styleUrls: ['./oee-calculation.component.css']
})
export class OeeCalculationComponent implements OnInit {

  Schedule: ScheduleTracker;
  constructor(
    private dialogRef: MatDialogRef<OeeCalculationComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,

  ) {
    this.Schedule = data;
  }

  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close(false);
  }

  Float(value){
    return parseFloat(value)
  }

  msToTime(end,start) {
    let duration = parseFloat(end)-parseFloat(start);
    if(duration > 0){
      let seconds = Math.floor((duration / 1000) % 60);
      let minutes = Math.floor((duration / (1000 * 60)) % 60);
      let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      let hrs = (hours < 10) ? "0" + hours : hours;
      let min = (minutes < 10) ? "0" + minutes : minutes;
      let sec = (seconds < 10) ? "0" + seconds : seconds;
      return hrs + ":" + min + ":" + sec;
    }
    return 0;

  }

  view(job:ScheduleTracker){
    this.dialog.open(DowntimeDetailsComponent, {
      width: '80%',
      height: 'auto',
      data: job.DownTimeDetail,
      disableClose:true
    });

  }

  hoursDisplay(hr){
    if(hr > 0){
      let min = hr - parseFloat(hr.toString().split('.')[0])
      min = min * 60
      let sec = min - parseFloat(min.toString().split('.')[0])
      sec = sec * 60 ;
      let hrs1 = (hr < 10) ? "0" + hr.toString().split('.')[0] : hr.toString().split('.')[0];
      let min1 = (min < 10) ? "0" + min.toString().split('.')[0] : min.toString().split('.')[0];
      let sec1 = (sec < 10) ? "0" + sec.toString().split('.')[0] : sec.toString().split('.')[0];
      return hrs1 + ":" + min1 + ":" + sec1;
    }
    return 0;
  }
}
