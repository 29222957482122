import { data } from 'jquery';
import { Invoice_PackingList } from './../Object_Classes/Invoice/Invoice';
import { PackingList } from 'src/app/Services/Object_Classes/PackingList/PackingList';
import { element } from 'protractor';
import { AngularFireDatabase } from '@angular/fire/database';
import { Invoice, Invoice_PO_List, Invoice_PO_Part_List } from '../Object_Classes/Invoice/Invoice';


export class InvoiceDB_controller {
  constructor(private db: AngularFireDatabase) {
  }


  /************** Invoice ****************/
  async getInvoiceList(): Promise<Invoice[]> {
    let InvoiceList: Invoice[] = [];
    var snapshot = await this.db.database.ref('Invoice').once('value');

    if (snapshot.exists()) {
      snapshot.forEach((function (childSnapshot) {
        let invoice = new Invoice();
        let invoice_p: Invoice_PackingList[] = [];
        invoice.Invoice_No = childSnapshot.key;
        invoice.Total_Price = childSnapshot.child('Total Price').val();
        invoice.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
        invoice.Updated_By = childSnapshot.child('_Updated_By').val();
        invoice.Currency = childSnapshot.child('Currency').val();
        invoice.Status = childSnapshot.child('Status').val();

        childSnapshot.child('PackingList').forEach(childSnapshot2 => {
          let packing = new Invoice_PackingList;
          packing.PackingListNo = childSnapshot2.key;
          childSnapshot2.forEach(childSnapshot3=>{
            let invoice_polist = new Invoice_PO_List;
            let invoice_popartlistl: Invoice_PO_Part_List[] = [];
            invoice_polist.PO_No = childSnapshot3.key;
            childSnapshot3.forEach((function (childSnapshot4) {
              let invoice_popartlist = new Invoice_PO_Part_List;
              invoice_popartlist.Part_No = childSnapshot4.key;
              invoice_popartlist.Part_Quantity = childSnapshot4.child("Part Quantity").val();
              invoice_popartlist.MouldPrice = childSnapshot4.child("Mould Price").val();
              invoice_popartlist.PartPrice = childSnapshot4.child("Part Price").val();
              invoice_popartlist.Is_Shipped = childSnapshot4.child("Status").val();
              invoice_popartlistl.push(invoice_popartlist);
            }));
            invoice_polist.Invoice_PO_Part_List = invoice_popartlistl;
            packing.POList.push(invoice_polist);
          })
          invoice_p.push(packing);
        });
        invoice.Invoice_PackingList = invoice_p;

        InvoiceList.push(invoice);


      }));
    }
    return InvoiceList;
  }

  add_or_update_Invoice(_newInvoice: any, email: string) {
    var updates = {};

    _newInvoice.packinglist.forEach((element:PackingList) => {
      element.PackingList_PO_List.forEach(data => {
        data.PackingList_PO_Part_List.forEach(data2 => {
          updates["/" + _newInvoice.InvoiceName + "/PackingList/" +element.PackingList_No+'/'+ data.PO_No + "/" + data2.Part_No + "/Part Quantity"] = data2.POQuantity;
          updates["/" + _newInvoice.InvoiceName + "/PackingList/" +element.PackingList_No+'/'+ data.PO_No + "/" + data2.Part_No + "/Mould Price"] = data2.Part_Mould_Price;
          updates["/" + _newInvoice.InvoiceName + "/PackingList/" +element.PackingList_No+'/'+ data.PO_No + "/" + data2.Part_No + "/Part Price"] = data2.Part_Price;
          updates["/" + _newInvoice.InvoiceName + "/PackingList/" +element.PackingList_No+'/'+ data.PO_No + "/" + data2.Part_No + "/Status"] = 'Created';
        });
      });
    });
    _newInvoice.packingListNo.forEach(element => {
      this.db.database.ref('PackingList/' + element + '/Added To Invoice').set(true);
      this.db.database.ref('PackingList/' + element + '/InvoiceNo').set(_newInvoice.InvoiceName);
    });
    updates["/" + _newInvoice.InvoiceName + '/_Created_Date'] = new Date();
    updates["/" + _newInvoice.InvoiceName + '/_Updated_Date'] = new Date();
    updates["/" + _newInvoice.InvoiceName + '/_Created_By'] = email;
    updates["/" + _newInvoice.InvoiceName + '/_Updated_By'] = email;
    updates["/" + _newInvoice.InvoiceName + '/Total Price'] = _newInvoice.TotalPrice;
    updates["/" + _newInvoice.InvoiceName + '/Currency'] = _newInvoice.currency;
    updates["/" + _newInvoice.InvoiceName + '/Status'] = 'Created';


    this.db.database.ref('Invoice').update(updates);
  }

  update_Invoice(invoice: Invoice, totalPrice, email) {
    var updates = {};

    invoice.Invoice_PackingList.forEach(element => {
      element.POList.forEach(data => {
        data.Invoice_PO_Part_List.forEach(data2 => {
          updates["/" + invoice.Invoice_No + "/PackingList/" + element.PackingListNo + '/' + data.PO_No + "/" + data2.Part_No + "/Part Quantity"] = data2.Part_Quantity;
          updates["/" + invoice.Invoice_No + "/PackingList/" + element.PackingListNo + '/' + data.PO_No + "/" + data2.Part_No + "/Mould Price"] = data2.MouldPrice;
          updates["/" + invoice.Invoice_No + "/PackingList/" + element.PackingListNo + '/' + data.PO_No + "/" + data2.Part_No + "/Part Price"] = data2.PartPrice;
        });
      });
    });


    updates["/" + invoice.Invoice_No + '/_Updated_Date'] = new Date();
    updates["/" + invoice.Invoice_No + '/_Updated_By'] = email;
    updates["/" + invoice.Invoice_No + '/Total Price'] = totalPrice;
    updates["/" + invoice.Invoice_No + '/Currency'] = invoice.Currency;


    this.db.database.ref('Invoice').update(updates);
  }


  delete_Invoice(invoice: Invoice) {
    invoice.Invoice_PackingList.forEach(element => {
      this.db.database.ref('/PackingList/' + element.PackingListNo + '/Added To Invoice').set(false);
      this.db.database.ref('PackingList/' + element.PackingListNo + '/InvoiceNo').set(null);

    })
    this.db.database.ref('/Invoice/' + invoice.Invoice_No).set(null);
  }


}
