export class Customer{
  private _Customer_ID: string;
  private _Customer_Name: string;
  private _Person_In_Charge: string;
  private _Contact: string;
  private _Email: string;
  private _Address: string;
  private _Last_Updated: Date;
  private _Created_Date: Date;
  private _Updated_By: string;
  private _Created_By: string;
  private _Currency: string;

  get Currency(): string {
    return this._Currency;
  }
  set Currency(value: string) {
    this._Currency = value;
  }

  get Person_In_Charge(): string {
    return this._Person_In_Charge;
  }
  set Person_In_Charge(value: string) {
    this._Person_In_Charge = value;
  }


  get Customer_ID(): string {
    return this._Customer_ID;
  }
  set Customer_ID(value: string) {
    this._Customer_ID = value;
  }
  get Customer_Name(): string {
    return this._Customer_Name;
  }
  set Customer_Name(value: string) {
    this._Customer_Name = value;
  }

  get Contact(): string {
    return this._Contact;
  }
  set Contact(value: string) {
    this._Contact = value;
  }

  get Email(): string {
    return this._Email;
  }
  set Email(value: string) {
    this._Email = value;
  }

  get Address(): string {
    return this._Address;
  }
  set Address(value: string) {
    this._Address = value;
  }

  get Last_Updated(): Date {
    return this._Last_Updated;
  }
  set Last_Updated(value: Date) {
    this._Last_Updated = value;
  }
  get Created_Date(): Date {
    return this._Created_Date;
  }
  set Created_Date(value: Date) {
    this._Created_Date = value;
  }

  get Updated_By(): string {
    return this._Updated_By;
  }
  set Updated_By(value: string) {
    this._Updated_By = value;
  }

  get Created_By(): string {
    return this._Created_By;
  }
  set Created_By(value: string) {
    this._Created_By = value;
  }

}
