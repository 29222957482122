import { CrushingMaterialDB_controller } from './../../../../Services/DB_Controller/CrushingMaterialDB_Controller';
import { CrushingMaterial } from './../../../../Services/Object_Classes/CrushingMaterial/CrushingMaterial';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-add-crushing-material',
  templateUrl: './add-crushing-material.component.html',
  styleUrls: ['./add-crushing-material.component.css']
})
export class AddCrushingMaterialComponent implements OnInit {


  crushingMaterial = new CrushingMaterial();
  CrushingMaterialDB_controllers: CrushingMaterialDB_controller = new CrushingMaterialDB_controller(this.db, this.firestore);
  crushList = [];
  ngOnInit() {
  }
  constructor(
    public dialogRef: MatDialogRef<AddCrushingMaterialComponent>,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    ) {

      this.CrushingMaterialDB_controllers.getCrushCodeList().then(r=>{
        this.crushList = r;
      })
    }

  close(): void {
    this.dialogRef.close();
  }

  confirm(){
    this.dialogRef.close(this.crushingMaterial);
  }




}
