import { element } from 'protractor';
import { AngularFireDatabase } from '@angular/fire/database';
import { v4 as uuidv4 } from 'uuid';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateFormatService } from '../Utilities/date-format.service';
import { Supplier } from '../Object_Classes/RawMaterial/Supplier';



export class SupplierDB_controller {
  constructor(private db: AngularFireDatabase, private firestore: AngularFirestore) {
  }

  private dateFormat = new DateFormatService();

  /************** Part ****************/
  async getSupplierList(): Promise<Supplier[]> {
    let SupplierList: Supplier[] = [];
    var snapshot = await this.db.database.ref('Supplier').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const sup = new Supplier();
        sup.Supplier_ID = childSnapshot.key;
        sup.Supplier_Name = childSnapshot.child('_Supplier_Name').val();
        sup.Contact = childSnapshot.child('_Contact').val();
        sup.Address = childSnapshot.child('_Address').val();
        sup.Email = childSnapshot.child('_Email').val();
        sup.Updated_By = childSnapshot.child('_Updated_By').val();
        sup.Created_By = childSnapshot.child('_Created_By').val();
        sup.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        sup.Person_In_Charge = childSnapshot.child('_Person_In_Charge').val();
        sup.Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());
        sup.Currency = childSnapshot.child('_Currency').val();

        SupplierList.push(sup);
      })
      ;
    }
    return SupplierList;
  }

  add_Supplier(_sup: Supplier,email: string) {
    var updates = {}
    updates['/' + uuidv4() + '/'] = _sup;
    _sup.Created_Date = new Date();
    _sup.Last_Updated = new Date();
    _sup.Created_By = email;
    _sup.Updated_By = email;
    this.db.database.ref('Supplier').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message: _sup.Supplier_Name + ' has been added by the ' + email,
      date: new Date()
    };
    this.firestore.collection('SupplierLog').doc(dateFormat).set({Date: new Date()});
    this.firestore.collection('SupplierLog').doc(dateFormat).collection('Supplier').add(info);
  }

  update_Supplier(_sup: Supplier,email: string) {
    var updates = {}
    updates['/' + _sup.Supplier_ID + '/'] = _sup;
    _sup.Last_Updated = new Date();
    _sup.Updated_By = email;
    this.db.database.ref('Supplier').update(updates);
    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
    const info = {
      message:  _sup.Supplier_Name + ' has been updated by the ' + email,
      date: new Date()
    };
    this.firestore.collection('SupplierLog').doc(dateFormat).set({Date: new Date()});
    this.firestore.collection('SupplierLog').doc(dateFormat).collection('Supplier').add(info);
  }

  async search_Supplier(id: string): Promise<Supplier> {
    let searched_sup = new Supplier();
    var snapshot = await this.db.database.ref('Supplier').child(id).once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        searched_sup.Supplier_ID = childSnapshot.key;
        searched_sup.Supplier_Name = childSnapshot.child('_Supplier_Name').val();
        searched_sup.Contact = childSnapshot.child('_Contact').val();
        searched_sup.Address = childSnapshot.child('_Address').val();
        searched_sup.Email = childSnapshot.child('_Email').val();
        searched_sup.Updated_By = childSnapshot.child('_Updated_By').val();
        searched_sup.Created_By = childSnapshot.child('_Created_By').val();
        searched_sup.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        searched_sup.Person_In_Charge = childSnapshot.child('_Person_In_Charge').val();
        searched_sup.Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());
        searched_sup.Currency = childSnapshot.child('_Currency').val();

      });
    }
    return searched_sup;
  }

  async search_SupplierByName(name: string): Promise<Supplier> {
    let searched_sup = new Supplier();
    var snapshot = await this.db.database.ref('Supplier').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        if(childSnapshot.child('_Supplier_Name').val() === name){
          searched_sup.Supplier_ID = childSnapshot.key;
          searched_sup.Supplier_Name = childSnapshot.child('_Supplier_Name').val();
          searched_sup.Contact = childSnapshot.child('_Contact').val();
          searched_sup.Address = childSnapshot.child('_Address').val();
          searched_sup.Email = childSnapshot.child('_Email').val();
          searched_sup.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_sup.Created_By = childSnapshot.child('_Created_By').val();
          searched_sup.Person_In_Charge = childSnapshot.child('_Person_In_Charge').val();
          searched_sup.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_sup.Currency = childSnapshot.child('_Currency').val();
          searched_sup.Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());
        }
      });
    }
    return searched_sup;
  }


  async search_Partial_Supplier(name: string): Promise<Supplier[]> {
    let supList: Supplier[] = [];
    var snapshot = await this.db.database.ref('Supplier').once('value');

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let searched_sup = new Supplier();
        var get_name = childSnapshot.key;
        if (get_name.includes(name)) {
          searched_sup.Supplier_ID = childSnapshot.key;
          searched_sup.Supplier_Name = childSnapshot.child('_Supplier_Name').val();
          searched_sup.Contact = childSnapshot.child('_Contact').val();
          searched_sup.Address = childSnapshot.child('_Address').val();
          searched_sup.Email = childSnapshot.child('_Email').val();
          searched_sup.Updated_By = childSnapshot.child('_Updated_By').val();
          searched_sup.Created_By = childSnapshot.child('_Created_By').val();
          searched_sup.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
          searched_sup.Person_In_Charge = childSnapshot.child('_Person_In_Charge').val();
          searched_sup.Currency = childSnapshot.child('_Currency').val();
          searched_sup.Last_Updated = new Date(childSnapshot.child('_Last_Updated').val());
          supList.push(searched_sup);
        }
      });
    }

    return supList;
  }


  delete_Supplier(id: string) {
    this.db.database.ref('/Supplier/' + id).set(null);
  }


}
