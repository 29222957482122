import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { data } from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-update-quantity',
  templateUrl: './update-quantity.component.html',
  styleUrls: ['./update-quantity.component.css']
})
export class UpdateQuantityComponent implements OnInit {

  schedule : any
  constructor( private dialogRef: MatDialogRef<UpdateQuantityComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private spinner: NgxSpinnerService,
    private storage: AngularFireStorage,
    private dialog: MatDialog,) {
      this.schedule = data
     }

  ngOnInit() {
  }
  change(){
    this.schedule.POQuantity = this.schedule.Quantity
  }

  update(){
    this.dialogRef.close(this.schedule);
  }

}
