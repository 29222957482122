import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PurchaseOrderRaw } from 'src/app/Services/Object_Classes/RawMaterial/PORawMaterial';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {

  po = new PurchaseOrderRaw();
  recFlag = false;
  checkFlag = false;
  flag1 = false;
  flag2 = false;
  ngOnInit() {
  }
  constructor(
    public dialogRef: MatDialogRef<OrdersComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.po = data;
      let result1 = this.po.PO_RawMaterials.find(e=>e.ReceivedDetailsList.length>0);
      let result2 = this.po.PO_RawMaterials.find(e=>e.PoInRawMaterialList.length>0);
      if(result1)
        this.flag1 = true;
      if(result2)
        this.flag2 = true;

    }

  close(): void {
    this.dialogRef.close();
  }
  integer(q){
    return parseFloat(q);
  }

  showRec(){
    this.recFlag = !this.recFlag;
  }
  showCheck(){
    this.checkFlag = !this.checkFlag

  }

  openImage(url) {
    window.open(url, '_blank');
 }

}
