import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ScheduleTracker } from 'src/app/Services/Object_Classes/Machine/Machine';

@Component({
  selector: 'app-details-schedule',
  templateUrl: './details-schedule.component.html',
  styleUrls: ['./details-schedule.component.css']
})
export class DetailsScheduleComponent implements OnInit {

  schedule = new ScheduleTracker();
  ngOnInit() {
  }
  constructor(
    public dialogRef: MatDialogRef<DetailsScheduleComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
      this.schedule = data;
    }

  close(): void {
    this.dialogRef.close();
  }


}
