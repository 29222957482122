import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Part } from '../../Services/Object_Classes/Part/Part';
import { PartDB_controller } from '../../Services/DB_Controller/PartDB_controller';
import { PartTracker, PurchaseOrder } from '../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { PODB_controller } from '../../Services/DB_Controller/PODB_controller';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { WeightingDB_controller } from '../../Services/DB_Controller/WeightingDB_controller';
import { Weighting, WeightPartList } from '../../Services/Object_Classes/Weighting/Weighting';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.css'],
})
export class ShippingComponent implements OnInit {
  poFlag: boolean = false;
  partNumFlag: boolean = false;
  shipQtyFlag: boolean = false;
  sDateFlag: boolean = false;
  myControl = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  currentPONo: string;
  currentPartNum: string;
  TotAccQty: number[] = [];
  LastWeightDate = [];
  POPartList: PartTracker[] = [];
  POList: PurchaseOrder[] = [];
  PartList: Part[] = [];
  podb_Controller = new PODB_controller(this.db);
  partdb_Controller = new PartDB_controller(this.db,this.storage,this.firestore);
  WeightList: Weighting[] = [];
  weighting_Controller = new WeightingDB_controller(this.db);

  constructor(
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private storage:AngularFireStorage
  ) {}

  ngOnInit() {

    this.podb_Controller.getPOList().then(data => {
      this.POList = data;
    });
    this.partdb_Controller.getPartList().then(data => {
      this.PartList = data;
    });
    this.weighting_Controller.getWeightingList().then(data => {
      this.WeightList = data;
    })

    this.filteredOptions = this.myControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
  }

  getParts(PO_no: string) {
    this.options = [];
    this.LastWeightDate = [];
    this.currentPONo = PO_no;
    this.POList.some(data => {
      if(PO_no.includes(data.PO_No)) {
        data.PO_Part_List.forEach(data2 => {
          this.options.push(data2.PO_Part_No);
        });
        if (PO_no != '') {
          this.poFlag = true;
          return true;
        }
        else {
          this.poFlag = false;
        }
      }
      else {
        this.poFlag = false;
      }
    });
    this.validateInputField();
    this.ngOnInit();
  }

  getPartsInfo(PO_no:string, Part_no: string) {
    this.TotAccQty = [];
    this.currentPartNum = Part_no;
    this.WeightList.some(data => {
      if(PO_no.includes(data.PO_No)) {
        data.Weight_Part_List.forEach(data2 => {
          if(Part_no.match(data2.Part_No)) {
            this.TotAccQty.push(data2.Accept_Qty);
            this.LastWeightDate.push(data2.Weight_Date);

            if (Part_no != '') {
              this.partNumFlag = true;
              return true;
            }
            else {
              this.partNumFlag = false;
            }
          }
        })
      }
      else {
        this.POList.some(dataz => {
          if (PO_no.includes(dataz.PO_No)) {
            if (Part_no != '') {
              this.partNumFlag = true;
              return true;
            }
            else {
              this.partNumFlag = false;
            }
          }
        })
        return true;
      }
    });
    this.validateInputField();
    this.ngOnInit();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }

  shippingCheckout(TotQty:number, date:string) {
    this.PartList.forEach(data => {
        if(data.Part_No.match(this.currentPartNum)) {
          var updatePart = new Part();
          data.Stock_Quantity = +data.Stock_Quantity - +TotQty;
          updatePart.Stock_Quantity = data.Stock_Quantity;
          var updates = {};
          updates["/" + this.currentPartNum + "/Stock Quantity"] = updatePart.Stock_Quantity;
          this.db.database.ref('Part').update(updates);
        }
    });
    this.WeightList.forEach(data2 => {
      if(data2.PO_No.match(this.currentPONo)) {
        data2.Weight_Part_List.forEach(data3 => {
          if(data3.Part_No.match(this.currentPartNum)) {
            var updates = {};
            updates['/' + this.currentPONo + '/' + this.currentPartNum + '/Accepted Qty'] = +data3.Accept_Qty - +TotQty;

            this.db.database.ref('Weighting').update(updates);

            var qty = +data3.Accept_Qty - +TotQty;
            // if (qty == 0 && data3.Is_Weighted == "Done") {
            //   this.db.database.ref('/Weighting/' + this.currentPONo + '/' + this.currentPartNum).set(null);
            // }
          }
        });
      }
    });
    location.reload();
  }

  validateShipQty(fieldInfo: any) {
    if (fieldInfo == '') {
      this.shipQtyFlag = false;
    }
    else {
      this.shipQtyFlag = true;
    }
    this.validateInputField();
  }

  validateD(fieldInfo: any) {
    if (fieldInfo == '') {
      this.sDateFlag = false;
    }
    else {
      this.sDateFlag = true;
    }
    this.validateInputField();
  }

  validateInputField() {
    if (this.poFlag && this.shipQtyFlag && this.sDateFlag && this.partNumFlag) {
      (document.getElementById("checkout") as HTMLInputElement).disabled = false;
    }
    else {
      (document.getElementById("checkout") as HTMLInputElement).disabled = true;
    }
  }
}
