import { CrushingMaterial } from './../../../../Services/Object_Classes/CrushingMaterial/CrushingMaterial';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.css']
})
export class QRCODEComponent implements OnInit {
  qr :string
  url = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  title: string;
  batch: string;
  pigment: string;
  info: any;
  constructor(private dialogRef: MatDialogRef<QRCODEComponent>,
    @Inject(MAT_DIALOG_DATA) data : CrushingMaterial,
  ) {
    this.info = data;
    this.qr = data.ID;
    this.title = data.RawMaterial
    this.batch = data.MaterialBatch
    this.pigment = data.Pigment

  }

  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close();
  }
  getBase64Image(img) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  }

  downloadQRCode() {
    const doc = new jsPDF();
    const qrcode = document.getElementById("qr");
    const imageData = this.getBase64Image(qrcode.firstChild.firstChild);

    doc.text('Material Name :' + this.title, 20, 20);
    doc.text('Material Batch :' + this.batch, 20, 30);
    doc.text('Pigment :' + this.pigment, 20, 40);
    doc.text('Location :' + this.info.PaletteNumber, 20, 50);
    doc.text('Quantity :' + this.info.Stock, 20, 60);

    doc.addImage(imageData, 'JPG', 20, 65);
    doc.save('QR.pdf');

  //  const fileNameToDownload = this.title.replace(/\s/g,'') +  this.batch.replace(/\s/g,'') +  this.pigment.replace(/\s/g,'') + "_QRCode";
  //  const base64Img = document.getElementsByClassName('coolQRCode')[0].children[0]['src'];
  //  fetch(base64Img)
  //     .then(res => res.blob())
  //     .then((blob) => {
  //        // IE
  //        if (window.navigator && window.navigator.msSaveOrOpenBlob){
  //           window.navigator.msSaveOrOpenBlob(blob,fileNameToDownload);
  //        } else { // Chrome
  //           const url = window.URL.createObjectURL(blob);
  //           const link = document.createElement('a');
  //           link.href = url;
  //           link.download = fileNameToDownload;
  //           link.click();
  //        }
  //     })
}

}
