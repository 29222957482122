import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { InvoiceDB_controller } from 'src/app/Services/DB_Controller/InvoiceDB_controller';
import { PartDB_controller } from 'src/app/Services/DB_Controller/PartDB_controller';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { Invoice } from 'src/app/Services/Object_Classes/Invoice/Invoice';
import { PackingList } from 'src/app/Services/Object_Classes/PackingList/PackingList';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { PurchaseOrder, PartTracker } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-generate-info',
  templateUrl: './generate-info.component.html',
  styleUrls: ['./generate-info.component.css']
})
export class GenerateInfoComponent implements OnInit {

  poNo = '';
  totalPart: any;
  partNumber: any;
  quantity: any;
  po_controller: PODB_controller = new PODB_controller(this.db);
  invoice_controller: InvoiceDB_controller = new InvoiceDB_controller(this.db);
  availableMachineList: any[] = [];
  selectedMachineList: any[] = [];
  matSelectPO: PurchaseOrder[] = [];
  matSelectPart=[];
  addForm: FormGroup;
  clone = [];
  newPO: PurchaseOrder = new PurchaseOrder();
  shippingDate = new Date();
  shipDate = [];
  searchPart: any;
  searchPO: any;
  check = false;
  check2 = false;
  selectedParts: PartTracker[] = [];
  selectedPack: any = [];

  newInvoice: Invoice = new Invoice();
  email:string;
  @ViewChild('picker', {static: true}) picker: any;
  temporaryInvoiceNum: string = "";
  part_controller: PartDB_controller = new PartDB_controller(this.db, this.storage,this.firestore);
  partsList: Part[] = [];

  priceList: string = "0";
  linkPackingList: PackingList = new PackingList();
  selectedCurrency: string = 'MYR';

  public date: moment.Moment;

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required])
  });

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];
  packinglist : PackingList[] = []
  packinglistNumber = [];
  fixedPrice: string;
  constructor(
    private dialogRef: MatDialogRef<GenerateInfoComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private spinner : NgxSpinnerService,
    private firestore: AngularFirestore,
    private storage : AngularFireStorage
  ) {

    this.angularFireAuth.authState.subscribe(auth=>{
      this.email = auth.email;
    })
    this.temporaryInvoiceNum = data.invoiceNo;
    this.packinglist = data.packingList;
    this.selectedCurrency = this.packinglist[0].Currency;
    this.packinglist.forEach(element=>{
      this.packinglistNumber.push(element.PackingList_No);
      element.PackingList_PO_List.forEach(e=>{
        e.PackingList_PO_Part_List.forEach(r=>{
          if(r.Part_Mould_Price === '-')
            r.Part_Mould_Price = "0";
          this.priceList = (parseFloat(this.priceList) + (parseFloat(r.Part_Price) * parseFloat(r.POQuantity))  +(r.Part_Mould_Price == "-" ? 0 : parseFloat(r.Part_Mould_Price))).toFixed(2);

        })
      })
    })

    this.addForm = this.fb.group({
      name: data.invoiceNo,
      Price: this.priceList,
    });
    this.fixedPrice = this.priceList;

  }


  ngOnInit() {
  }



  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    if( isNaN(this.addForm.get('Price').value)){
      this.toast.warning("Invalid mould price. please check")
      return;
    }
    const addPOModel = {
      InvoiceName : this.addForm.get('name').value,
      TotalPrice: this.addForm.get('Price').value,
      packingListNo: this.packinglistNumber,
      packinglist: this.packinglist,
      currency: this.selectedCurrency
    };
    const dialogConfig = new MatDialogConfig;
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm generate this invoice?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async confirm => {
      if(confirm){
        this.invoice_controller.add_or_update_Invoice(addPOModel, this.email);
        this.dialogRef.close(addPOModel);
      }
    });


  }

  calculate(){
    this.priceList = this.fixedPrice;
    this.packinglist.forEach(element=>{
      element.PackingList_PO_List.forEach(e=>{
        e.PackingList_PO_Part_List.forEach((r,index)=>{
          this.priceList = (parseFloat(this.priceList) + parseFloat(r.Part_Mould_Price)).toFixed(2);
        })
      })
    })
    this.addForm.get('Price').setValue(this.priceList);
  }

  calculateUnit(){
    this.priceList = "0";
    this.packinglist.forEach(element=>{
      element.PackingList_PO_List.forEach(e=>{
        e.PackingList_PO_Part_List.forEach((r,index)=>{
          this.priceList = (parseFloat(this.priceList) + (parseFloat(r.Part_Price) * parseFloat(r.POQuantity))  +(r.Part_Mould_Price == "-" ? 0 : parseFloat(r.Part_Mould_Price))).toFixed(2);
        })
      })
    })
    this.fixedPrice = this.priceList;
    this.addForm.get('Price').setValue(this.priceList);
  }

}
