import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-select-machine',
  templateUrl: './select-machine.component.html',
  styleUrls: ['./select-machine.component.css']
})
export class SelectMachineComponent implements OnInit {

  machinelist = [];
  constructor(
    private dialogRef: MatDialogRef<SelectMachineComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
  ) {
    this.machinelist = data;
  }

  ngOnInit() { }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm(machine) {
    this.dialogRef.close(machine);
  }


}
